import React from 'react';
import { Grid, Paper, Typography, TextField, Button, Link, Container, Box } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import LockOpenIcon from '@mui/icons-material/LockOpen';
export default class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
     
    }
  }
  
  render() {
    return (
     <div style={{backgroundColor:"black",height:600}}>
         <Grid container>
             <Grid height={600} sm={3}>
                 <div style={{height:400,width:400,margin:'9vh',textAlign:'center'}}>
                    <h3 style={{color:'white'}}>
                        Twitter Feed
                    </h3>
                    <p style={{color:'white'}}>
                    #exploremadu #NammaKarnataka #Tourism #KarnatakaDiaries #TravelKarnataka #Travel #traveldiaries #travelgram… https://t.co/equUjVaC0Z
                    </p>
                 </div>
             
             </Grid>
             <Grid sm={3} >

             <div style={{height:400,width:400,margin:'9vh',textAlign:'center'}}>
                    <h3 style={{color:'white'}}>
                        Twitter Feed
                    </h3>
                    <p style={{color:'white'}}>
                    #exploremadu #NammaKarnataka #Tourism #KarnatakaDiaries #TravelKarnataka #Travel #traveldiaries #travelgram… https://t.co/equUjVaC0Z
                    </p>
                 </div>
             
             </Grid>
             <Grid sm={3} >

             <div style={{height:400,width:400,margin:'9vh',textAlign:'center'}}>
                    <h3 style={{color:'white'}}>
                        Twitter Feed
                    </h3>
                    <p style={{color:'white'}}>
                    #exploremadu #NammaKarnataka #Tourism #KarnatakaDiaries #TravelKarnataka #Travel #traveldiaries #travelgram… https://t.co/equUjVaC0Z
                    </p>
                 </div>
             
             </Grid>
             <Grid sm={3} >

             <div style={{height:400,width:400,margin:'9vh',textAlign:'center'}}>
                    <h3 style={{color:'white'}}>
                        Twitter Feed
                    </h3>
                    <p style={{color:'white'}}>
                    #exploremadu #NammaKarnataka #Tourism #KarnatakaDiaries #TravelKarnataka #Travel #traveldiaries #travelgram… https://t.co/equUjVaC0Z
                    </p>
                 </div>
             
             </Grid>
         </Grid>
     </div>
    );
  }
}