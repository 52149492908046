import * as React from 'react';
import Login from './components/login';
import Banner from './components/Banner';
import Navigationbar from './components/Navigationbar';
import ImageList from './components/ImageList';
import Footer from './components/Footer';

export default function BasicTable() {
  return (
    <>
     <Login></Login>
    {/* <Navigationbar></Navigationbar>
    <Banner></Banner>
    <ImageList></ImageList>
    <Footer></Footer> */}
    </>
    
  );
}
