import React from 'react';
import { Grid, Paper, Typography, TextField, Button, Link, Container, Box } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Alert from 'react-bootstrap/Alert';
import LockOpenIcon from '@mui/icons-material/LockOpen';
export default class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      usernameError: false,
      passwordError: false,
      UsernameErrorShow: '',
      PasswordErrorShow: ''
    }
  }
  UsernameChange = (value) => {
    if (value.target.value == '') {
      this.setState({
        usernameError: true,
        UsernameErrorShow: 'Please Enter Username'
      })

    } else {
      this.setState({
        username: value.target.value,
        UsernameErrorShow: '',
        usernameError: false
      })
    }
  }

  PasswordChange = (value) => {
    if (value.target.value == '') {
      this.setState({
        passwordError: true,
        PasswordErrorShow: 'Please Enter Username'
      })

    } else {
      this.setState({
        password: value.target.value,
        passwordError: false,
        PasswordErrorShow: ''
      })
    }


  }
  render() {
    return (
    //   <Alert dismissible variant="danger">
    //   <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
    //   <p>
    //     Change this and that and try again.
    //   </p>
    // </Alert>
      <Grid>
        <Paper elevation={16} style={{ padding: 20, height: 450, width: 400, margin: '50px auto', backgroundColor: "yellowgreen" }}>
          <Grid align='center'>
            <Avatar sx={{ width: 50, height: 50 }} style={{ backgroundColor: "blue" }}>
              <LockOpenIcon />
            </Avatar>
            <Typography style={{}}>
              <h2>Sign In</h2>
            </Typography>
          </Grid>
          <TextField
            error={this.state.usernameError}
            required
            fullWidth
            label="Username"
            variant="outlined"
            margin="normal"
            onChange={this.UsernameChange}
            helperText={this.state.UsernameErrorShow}
          />

          <TextField
            error={this.state.passwordError}
            required
            fullWidth
            label="Password"
            variant="outlined"
            margin="normal"
            type='password'
            onChange={this.PasswordChange}
            helperText={this.state.PasswordErrorShow}
             />

          <Button type='submit' style={{ marginTop: 40 }} fullWidth variant="contained" size="large">Login</Button>
          {/* <Typography style={{marginTop:20}}>
            create a new account
            <Link href="#" underline="none">
              {' sign up'}
            </Link>
          </Typography> */}
        </Paper>
      </Grid >
    );
  }
}