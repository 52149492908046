import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';

export default class ImageLists extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          imageopacity:1
        }
      }

    ImageMouseOver=(e)=>{
         console.log(e.target.classList[1])
         document.getElementsByClassName(e.target.classList[1])[0].style.opacity = 1;
         document.getElementsByClassName(e.target.classList[1])[0].style.transitionDuration = ".3s";
         document.getElementsByClassName(e.target.classList[1])[0].style.transform = "scale(1.1)";
         
       // this.setState({imageopacity:.7})

    }
    ImageMouseLeave=(e)=>{

        //this.setState({imageopacity:1})
        document.getElementsByClassName(e.target.id)[0].style.opacity = .7;
        document.getElementsByClassName(e.target.classList[1])[0].style.transitionDuration = ".3s";
        document.getElementsByClassName(e.target.classList[1])[0].style.transform = "scale(1)";


    }
    render() {
    return (
        <div style={{margin:120}}>
            <ImageList sx={{ width: "100%" }} cols={4} rowHeight={400}>
                {itemData.map((item,key1) => (
                    <ImageListItem style={{overflow: 'hidden'}} key={item.img}>
                        <img
                            src={item.img}
                            srcSet={item.img}
                            alt={item.title}
                            onMouseEnter={this.ImageMouseOver}
                            onMouseLeave={this.ImageMouseLeave}
                            className={`imagecls${key1}`}
                            id={`imagecls${key1}`}
                            style={{opacity:.7}}
                        />
                        <ImageListItemBar
                            title={item.title}
                            subtitle={item.author}
                            actionIcon={
                                <IconButton
                                    sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                    aria-label={`info about ${item.title}`}
                                >
                                    <InfoIcon />
                                </IconButton>
                            }
                        />
                    </ImageListItem>
                ))}
            </ImageList>
        </div>
    )};
}

const itemData = [
    {
        img: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
        title: 'Breakfast',
    },
    {
        img: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
        title: 'Burger',
    },
    {
        img: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
        title: 'Camera',
    },
    {
        img: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
        title: 'Coffee',
    },
    {
        img: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
        title: 'Hats',
    },
    {
        img: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
        title: 'Honey',
    },
    {
        img: 'https://images.unsplash.com/photo-1516802273409-68526ee1bdd6',
        title: 'Basketball',
    },
    {
        img: 'https://images.unsplash.com/photo-1518756131217-31eb79b20e8f',
        title: 'Fern',
    },
    {
        img: 'https://images.unsplash.com/photo-1597645587822-e99fa5d45d25',
        title: 'Mushrooms',
    },
    {
        img: 'https://images.unsplash.com/photo-1567306301408-9b74779a11af',
        title: 'Tomato basil',
    },
    {
        img: 'https://images.unsplash.com/photo-1471357674240-e1a485acb3e1',
        title: 'Sea star',
    },
    {
        img: 'https://images.unsplash.com/photo-1589118949245-7d38baf380d6',
        title: 'Bike',
    },
];
