import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Navbar from 'react-bootstrap/Navbar'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Typography } from '@mui/material';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

export default class Navigationbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ismenuOpen: false

        }
    }

    Mouseenter = () => {
        this.setState({ ismenuOpen: true })
    }

    Mouseleave = () => {
        this.setState({ ismenuOpen: false })
    }
    toggle() {
        this.setState(prevState => ({
            ismenuOpen: !prevState.ismenuOpen
        }));
      }



    Item = styled(Paper)(({ theme }) => ({
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',

    }));

    render() {
        return (
            <Box>
                <Grid container spacing={2}>
                    <Grid height={110} item xs={2}>
                        <img width={310} height={120} src={process.env.PUBLIC_URL + `/logo.png`} />

                    </Grid>
                    <Grid item xs={10} >
                        <Grid bgcolor={"black"} height={30}>
                        </Grid >
                        <Grid border={1} height={80} container>
                            <Grid textAlign={'center'} borderRight={1} item xs={2}>
                                <Dropdown className="d-inline-block" style={{ marginTop: 18}} isOpen={this.state.ismenuOpen} onMouseEnter={this.Mouseenter} onMouseLeave={this.Mouseleave} toggle={this.toggle}>
                                    <DropdownToggle caret color='white' style={{marginBottom:20}}>
                                        Home
                                    </DropdownToggle >
                                    <DropdownMenu style={{ marginTop: 21 }}>
                                        <DropdownItem header>Header</DropdownItem>
                                        <DropdownItem disabled>Action</DropdownItem>
                                        <DropdownItem>Another Action</DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem>Another Action</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                {/* <Typography fontWeight={'bold'} marginTop={3}>Home</Typography> */}
                            </Grid>
                            <Grid textAlign={'center'} borderRight={1} item xs={2}>
                                <Dropdown style={{ marginTop: 18 }} isOpen={false} >
                                    <DropdownToggle caret color='white'>
                                        Experience
                                    </DropdownToggle >
                                    <DropdownMenu style={{ marginTop: 21, backgroundColor: 'black' }}>
                                        <DropdownItem style={{ color: 'white' }}>Header</DropdownItem>
                                        <DropdownItem disabled>Action</DropdownItem>
                                        <DropdownItem>Another Action</DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem>Another Action</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </Grid>
                            <Grid textAlign={'center'} borderRight={1} item xs={2}>
                                <Typography fontWeight={'bold'} marginTop={3}>Destination</Typography>
                            </Grid>
                            <Grid textAlign={'center'} borderRight={1} item xs={2}>
                                <Typography fontWeight={'bold'} marginTop={3}>Things To Do</Typography>
                            </Grid>
                            <Grid textAlign={'center'} borderRight={1} item xs={2}>
                                <Typography fontWeight={'bold'} marginTop={3}>Whats New</Typography>
                            </Grid>
                            <Grid textAlign={'center'} borderRight={1} item xs={2}>
                                <Typography fontWeight={'bold'} marginTop={3}>Gallery</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </Box>
        );
    }
}